import * as React from 'react';
import styles from './Header.module.css';
import { ReactComponent as Logo } from '../../logo_horizontal.svg';

type IProps = {
  title?: string;
  tagline?: string;
};

const Header: React.FC<IProps> = ({ title, tagline }) => (
  <>
    <header className={styles.header}>
      <Logo/>
      <h1 className={styles.header__title}>{title}</h1>
      {tagline ? <p className={styles.header__tagline}>{tagline}</p> : null}
    </header>
    <div className={styles.wave}>

    </div>
  </>
);

export default Header;
